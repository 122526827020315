<template>
    <el-dialog
        :append-to-body="true"
        :visible.sync="visible_in_line"
        :close-on-click-modal="true"
        width="440px"
    >
        <div slot="title">
            <div class="text-left truncate w-4/5 text-base font-semibold">
                {{ $t('common.create_configuration') }}
            </div>
        </div>
        <el-form ref="rule_form" :model="form_create_statistical">
            <div class="flex flex-col space-y-4">
                <div class="flex-1">
                    <p class="component-label">
                        {{ $t('common.file_name_google_sheet') }}
                    </p>
                    <el-form-item prop="advertiser_id">
                        <el-input
                            v-model="form_create_statistical.file_name"
                            type="text"
                            size="small"
                            :placeholder="$t('input.placeholder.please_input')"
                        >
                        </el-input>
                    </el-form-item>
                </div>

                <div class="flex-1">
                    <p class="component-label">
                        {{ $t('common.ads_account') }}
                    </p>
                    <el-form-item prop="advertiser_id">
                        <el-select
                            v-model="form_create_statistical.advertiser_id"
                            size="small"
                            filterable
                            :placeholder="$t('input.placeholder.please_select')"
                            class="w-full"
                        >
                            <el-option-group :label="$t('common.ads_account')">
                                <el-option
                                    v-for="item in ad_account_list"
                                    :key="item.advertiser_id"
                                    :label="item.name"
                                    :value="item.advertiser_id"
                                >
                                </el-option>
                            </el-option-group>
                        </el-select>
                    </el-form-item>
                </div>

                <div class="flex-1">
                    <p class="component-label">{{ $t('common.time') }}</p>
                    <el-form-item prop="time">
                        <el-select
                            v-model="form_create_statistical.time"
                            size="small"
                            filterable
                            :placeholder="$t('input.placeholder.please_select')"
                            class="w-full"
                        >
                            <el-option
                                v-for="item in time_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <div class="flex-1">
                    <p class="component-label">
                        {{ $t('common.number_zalo_to_noti') }}
                    </p>
                    <el-form-item prop="noti">
                        <el-input
                            v-model="form_create_statistical.noti_config.value"
                            type="text"
                            size="small"
                            :placeholder="$t('input.placeholder.please_input')"
                        >
                        </el-input>
                    </el-form-item>
                </div>

                <div class="flex-1">
                    <p class="component-label">
                        {{ $t('common.data_field') }}
                    </p>
                    <el-form-item prop="noti">
                        <el-select
                            v-model="form_create_statistical.field_list"
                            size="small"
                            filterable
                            multiple
                            :placeholder="$t('input.placeholder.please_select')"
                            class="w-full"
                        >
                            <el-option
                                v-for="item in p__trend_basic_chart_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <div class="flex-1">
                    <p class="component-label">
                        {{ $t('common.google_drive_path') }}
                    </p>
                    <el-form-item prop="advertiser_id">
                        <el-input
                            v-model="form_create_statistical.folder_url"
                            type="text"
                            size="small"
                            :placeholder="$t('input.placeholder.please_input')"
                        >
                        </el-input>
                    </el-form-item>
                </div>
            </div>
        </el-form>

        <template slot="footer">
            <div class="flex items-center justify-end mt-4">
                <el-button size="small" @click="visible_in_line = false">
                    {{ $t('button.close') }}
                </el-button>

                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    @click="createStatistical"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import { createStatistical } from '@/services/atosa-tiktok-ads/statistical'

export default {
    props: [
        'visible',
        'tiktok_business_id',
        'getStatisticals',
        'ad_account_list'
    ],

    data() {
        return {
            time_options: [],
            form_create_statistical: {
                file_name: '',
                advertiser_id: '',
                time: 1,
                noti_config: {
                    channel: 'zalo',
                    value: ''
                },
                folder_id: '',
                field_list: [],
                active: true,
                folder_url: ''
            }
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    mounted() {
        this.genTimeOptions()
    },

    methods: {
        extractGoogleDriveFolderID(url) {
            // Tạo một đối tượng URL từ chuỗi URL
            var urlObj = new URL(url)

            // Kiểm tra nếu URL không phải của Google Drive
            if (!urlObj.hostname.includes('drive.google.com')) {
                return ''
            }

            // Chia phần đường dẫn của URL thành các phần riêng biệt
            var pathSegments = urlObj.pathname.split('/')

            // Tìm vị trí của 'folders' trong đường dẫn
            var folderIndex = pathSegments.indexOf('folders')

            // Kiểm tra nếu 'folders' không có trong đường dẫn
            if (folderIndex === -1 || folderIndex + 1 >= pathSegments.length) {
                return ''
            }

            // Lấy ID thư mục (phần nằm ngay sau 'folders' trong đường dẫn)
            var folderID = pathSegments[folderIndex + 1]

            return folderID
        },

        genTimeOptions() {
            const temp = []

            for (let index = 1; index < 25; index++) {
                temp.push({
                    label: this.convertTo12HourFormat(index),
                    value: index
                })
            }

            this.time_options = temp
        },

        convertTo12HourFormat(hour) {
            if (hour < 1 || hour > 24) {
                return 'Invalid hour'
            }

            let period = 'sáng'
            let convertedHour = hour

            if (hour === 12) {
                period = 'trưa'
            } else if (hour > 12 && hour < 24) {
                convertedHour = hour - 12
                period = 'chiều'
            } else if (hour === 24) {
                convertedHour = 12
                period = 'đêm'
            }

            return `${convertedHour} giờ ${period}`
        },

        async createStatistical() {
            try {
                const folder_id = this.form_create_statistical.folder_url
                    ? this.extractGoogleDriveFolderID(
                          this.form_create_statistical.folder_url
                      )
                    : ''

                await createStatistical(this.tiktok_business_id, {
                    ...this.form_create_statistical,
                    folder_id
                })

                this.p__showNotify('success', 'Tạo cấu hình thành công')

                this.visible_in_line = false

                this.getStatisticals()
            } catch (error) {
                console.error(error)

                this.p__showNotify('warning', 'Tạo cấu hình thất bại')
            }
        }
    }
}
</script>
