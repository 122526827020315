<template>
    <div>
        <div class="component-box">
            <div class="flex items-center space-x-2">
                <p class="font-semibold">
                    {{ $t('common.integration_google') }}
                </p>

                <div class="!ml-auto"></div>

                <div v-if="google_info">
                    <el-tag effect="plain" size="small">
                        {{ google_info.display_name }}
                    </el-tag>
                </div>

                <el-button size="small" slot="append" @click="handleOpenAuthen">
                    {{ $t('common.google_account_verification') }}</el-button
                >

                <el-button
                    type="primary"
                    size="small"
                    @click="show_create_statistical_dialog = true"
                >
                    {{ $t('common.create_configuration') }}
                </el-button>
            </div>

            <el-alert
                :title="$t('common.desc_integration_google')"
                type="success"
                class="mt-4 mb-2"
                :closable="false"
            >
            </el-alert>

            <el-table v-loading="m__loading" :data="statistical_list">
                <el-table-column label="#" type="index" width="60" />

                <el-table-column :label="$t('table.header.on_off')" width="100">
                    <template #default="scope">
                        <el-switch
                            :value="scope.row.active"
                            @change="handleChangeActive(scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column label="ID" width="180">
                    <template slot-scope="scope">
                        {{ scope.row._id }}
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$t('common.file_name_google_sheet')"
                    width="180"
                >
                    <template slot-scope="scope">
                        {{ scope.row.file_name }}
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$t('common.google_drive_path')"
                    width="180"
                >
                    <template slot-scope="scope">
                        {{
                            scope.row.folder_id
                                ? `https://drive.google.com/drive/folders/${scope.row.folder_id}`
                                : ''
                        }}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('common.time')" width="100">
                    <template slot-scope="scope">
                        {{ convertTo12HourFormat(scope.row.time) }}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('common.ads_account')" width="180">
                    <template slot-scope="scope">
                        <p>{{ genNameAdAccount(scope.row.advertiser_id) }}</p>
                        <p>{{ scope.row.advertiser_id }}</p>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('common.data_field')">
                    <template slot-scope="scope">
                        {{ scope.row.field_list.join(',') }}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('common.noti_channel')" width="180">
                    <template slot-scope="scope">
                        {{ scope.row.noti_config.channel }} -
                        {{ scope.row.noti_config.value }}
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$t('common.option')"
                    fixed="right"
                    width="200"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="small"
                            @click="handleEditItem(scope.row)"
                        >
                            {{ $t('button.edit') }}
                        </el-button>
                        <el-button
                            type="danger"
                            size="small"
                            @click="deleteStatistical(scope.row._id)"
                        >
                            {{ $t('button.delete') }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <CreateStatisticalDialog
            :visible.sync="show_create_statistical_dialog"
            :tiktok_business_id="
                m__selected_tiktok_business
                    ? m__selected_tiktok_business.id
                    : ''
            "
            :getStatisticals="getStatisticals"
            :ad_account_list="ad_account_list"
        ></CreateStatisticalDialog>

        <EditStatisticalDialog
            :item="edit_item"
            :visible.sync="show_edit_statistical_dialog"
            :tiktok_business_id="
                m__selected_tiktok_business
                    ? m__selected_tiktok_business.id
                    : ''
            "
            :getStatisticals="getStatisticals"
            :ad_account_list="ad_account_list"
        ></EditStatisticalDialog>
    </div>
</template>

<script>
import {
    getAuthenGoogleLink,
    authenGoogle,
    getGoogleInfo,
    getStatisticals,
    deleteStatistical
} from '@/services/atosa-tiktok-ads/statistical'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import CreateStatisticalDialog from './create-statistical-dialog'
import EditStatisticalDialog from './edit-statistical-dialog'
import { editStatistical } from '@/services/atosa-tiktok-ads/statistical'
import { getAdvertiserAccounts } from '@/services/atosa-tiktok-ads/advertiser-account'

export default {
    components: {
        CreateStatisticalDialog,
        EditStatisticalDialog
    },

    mixins: [selectedTiktokBusinessMixin],

    data() {
        return {
            edit_item: null,
            show_edit_statistical_dialog: false,
            show_create_statistical_dialog: false,
            statistical_list: [],
            google_info: null,
            ad_account_list: [],
            google_link: ''
        }
    },

    watch: {
        m__selected_tiktok_business() {
            this.getStatisticals()

            this.getAllAdAccount()
        }
    },

    mounted() {
        this.getAllAdAccount()

        this.getGoogleInfo()

        this.getAuthenGoogleLink()

        this.getStatisticals()

        const { code } = this.$route.query

        if (code) {
            this.authenGoogle(code)
        }
    },

    methods: {
        genNameAdAccount(advertiser_id) {
            const item = this.ad_account_list.find(
                (item) => item.advertiser_id === advertiser_id
            )

            if (item) {
                return item.name
            }

            return ''
        },

        async getAllAdAccount() {
            if (!this.m__selected_tiktok_business) {
                return
            }

            const response = await getAdvertiserAccounts(
                this.m__selected_tiktok_business.id,
                ''
            )

            this.ad_account_list = response.data.filter(
                (item) => item.belong_to_atosa
            )
            console.log(this.ad_account_list)
        },

        async handleChangeActive(item) {
            try {
                await editStatistical(this.m__selected_tiktok_business.id, {
                    ...item,
                    config_id: item._id,
                    active: !item.active
                })

                this.p__showNotify('success', 'Sửa cấu hình thành công')

                this.getStatisticals()
            } catch (error) {
                this.p__showNotify('warn', 'Sửa cấu hình thất bại')
            }
        },

        handleEditItem(item) {
            this.edit_item = item

            this.show_edit_statistical_dialog = true
        },

        convertTo12HourFormat(hour) {
            if (hour < 1 || hour > 24) {
                return 'Invalid hour'
            }

            let period = 'sáng'
            let convertedHour = hour

            if (hour === 12) {
                period = 'trưa'
            } else if (hour > 12 && hour < 24) {
                convertedHour = hour - 12
                period = 'chiều'
            } else if (hour === 24) {
                convertedHour = 12
                period = 'đêm'
            }

            return `${convertedHour} giờ ${period}`
        },

        async deleteStatistical(id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await deleteStatistical(this.m__selected_tiktok_business.id, id)

                this.getStatisticals()

                this.p__showNotify(
                    'success',
                    this.$t('common.delete_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        async getAuthenGoogleLink() {
            const response = await getAuthenGoogleLink()

            if (response.data) {
                this.google_link = response.data
            }
        },

        async authenGoogle(auth_code) {
            try {
                await authenGoogle(auth_code)

                this.p__showNotify(
                    'success',
                    'Xác minh tài khoản Google thành công'
                )

                setTimeout(() => {
                    window.location = `${this.p__group_route.general}/${this.p__route.statistical}`
                }, 1111)
            } catch (error) {
                console.error(error)

                this.p__showNotify(
                    'warning',
                    'Xác minh tài khoản Google không thành công'
                )
            }
        },

        async getGoogleInfo() {
            const response = await getGoogleInfo()

            if (response.data) {
                this.google_info = response.data
            } else {
                this.p__showNotify(
                    'warning',
                    'Quý khách cần xác minh tài khoản Google để tích hợp'
                )
            }
        },

        async getStatisticals() {
            if (!this.m__selected_tiktok_business) {
                return
            }

            const response = await getStatisticals(
                this.m__selected_tiktok_business.id
            )

            if (response.data) {
                this.statistical_list = response.data

                console.log(this.statistical_list)
            }
        },

        async handleOpenAuthen() {
            window.location = this.google_link
        }
    }
}
</script>
