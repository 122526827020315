import request from './_config'

const path = `statistical`

export function getAuthenGoogleLink() {
    return request({
        url: `${path}/google/get_link`,
        method: 'get'
    })
}

export function authenGoogle(auth_code) {
    return request({
        url: `${path}/google/auth?auth_code=${auth_code}`,
        method: 'post'
    })
}

export function getGoogleInfo() {
    return request({
        url: `${path}/google/info`,
        method: 'get'
    })
}

export function getStatisticals(tiktok_account_id) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'get'
    })
}

export function createStatistical(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function editStatistical(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'put',
        data
    })
}

export function deleteStatistical(tiktok_account_id, config_id) {
    return request({
        url: `${path}/${tiktok_account_id}?config_id=${config_id}`,
        method: 'delete'
    })
}
